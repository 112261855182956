import './App.css';
import {Scanner} from "./Scanner";
import {useEffect, useState} from "react";

function App() {

  const [userId, setUserId] = useState(localStorage.getItem('userId'));

  useEffect(() => {
    if (!userId) {
      const newUserId = crypto.randomUUID();
      console.log('setting userId', newUserId);
      localStorage.setItem('userId', newUserId);
      setUserId(newUserId);
    }
  }, []);

  useEffect(() => {
    fetch(`https://tegut.mudkips.de/api/getCards.php?userId=${userId}`, {
      method: 'get',
    })
      .then(res => res.json())
      .then(serverCards => {
        serverCards.forEach(updateCard);
      })
  }, []);

  const [cards, setCards] = useState([]);

  const now = new Date();
  const inSixMonths = new Date(now.getFullYear(), now.getMonth() + 6, now.getDate());
  const criticalDate = inSixMonths.toISOString().substring(0, 10);
  const cardCategories = [
    {
      name: 'Neu eingescannt',
      matches: card => !card.pin || `${card.pin}`.length < 4 || card.loading,
    },
    {
      name: 'Angebrochen',
      matches: card => card.credit > 0 && card.credit < 5 && card.validUntil >= criticalDate,
    },
    {
      name: 'Läuft bald ab',
      matches: card => card.credit > 0 && card.validUntil && card.validUntil < criticalDate,
    },
    {
      name: 'Wie neu',
      matches: card => card.credit === 5 && card.validUntil >= criticalDate,
    },
    {
      name: 'Kürzlich verbraucht',
      matches: card => card.credit === 0,
      style: {opacity: 0.3},
    },
    {
      name: 'Keine Ahnung, was hier los ist',
      regular: false,
      matches: card => cardCategories
        .filter(cardCategory => cardCategory.regular !== false)
        .find(cardCategory => cardCategory.matches(card)) === undefined,
    }
  ];

  const getCardCodeSuffix = (code) => {
    return `...${code.substring(12, 15)} ${code.substring(15, 16)}`;
  };

  const handleNewCode = (newCode) => {
    updateCard({code: newCode});
  }

  const pinChanged = card => {
    updateCard(card);
    if (card.pin.length === 4) {
      checkCard(card);
    }
  }

  function checkCard(card) {
    updateCard({...card, loading: true});
    fetch(`https://tegut.mudkips.de/api/`, {
      method: 'post',
      body: JSON.stringify({code: card.code, pin: card.pin, userId})
    })
      .then(res => res.json())
      .then(updatedCard => {
        if (card.code === updatedCard.code) {
          const before = [...cards];
          updateCard(updatedCard);
          console.log('updated card', updatedCard);
          console.log('before', before, 'after', cards);
        } else {
          console.warn('unable to update card', card, 'into', updatedCard);
        }
      });
  }

  const updateCard = (updatedCard) => {
    setCards(cards => {
      const filteredCards = [...cards].filter(card => card.code !== updatedCard.code);
      console.log('filtered cards', filteredCards);
      console.log('updatedCard', updatedCard);
      return [updatedCard, ...filteredCards];
    });
  }

  const isNumber = (n) => {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  };

  const euro = amount => amount.toFixed(2).replace('.', ',') + ' €';

  const amountSum = cards
    .map(card => card.credit)
    .filter(credit => isNumber(credit))
    .reduce((a, b) => a + b, 0);

  return <main className={'container'}>
    <Scanner handleNewCode={(newCode) => handleNewCode(newCode)}/>
    {(cards.length > 0) &&
      <>
        <div style={{textAlign: "center", fontSize: "2rem", fontWeight: "bolder"}}>
          {euro(amountSum)}
        </div>
        <table className={'cardTable'}>
          <thead>
          <tr>
            <th>Nummer</th>
            <th>PIN</th>
            <th>Wert</th>
            <th>Bis</th>
          </tr>
          </thead>
          <tbody>

          {cardCategories.map(cardCategory =>
            cards.find(card => cardCategory.matches(card)) &&
            <>
              <tr>
                <td colspan={4}>
                  <div style={{...cardCategory.style, fontWeight: 'bold', fontSize: '80%', marginTop: '2rem'}}>
                    {cardCategory.name}
                  </div>
                </td>
              </tr>
              {
                cards
                  .filter(card => cardCategory.matches(card))
                  .sort((cardA, cardB) => cardA.pin - cardB.pin)
                  .map(card =>
                    <tr key={card.code} style={{...cardCategory.style}} className={'newCard'}>
                      <td>{getCardCodeSuffix(card.code)}</td>
                      <td>
                        {(card.pin && card.pin.length === 4)
                          ? card.pin
                          : <input
                            type={'number'}
                            onChange={event => pinChanged({...card, pin: event.target.value})}
                            value={card.pin ? card.pin : ''}
                            autoFocus={true}
                            className={'pin'}
                            size={4}
                          />}
                      </td>
                      <td>{card.loading &&
                        <span aria-busy={true}></span>}{isNumber(card.credit) && euro(parseFloat(card.credit))}</td>
                      <td>{card.credit ? card.validUntil : card.checkDate?.substring(0, 10)}</td>
                    </tr>
                  )
              }</>
          )}
          </tbody>
        </table>
      </>
    }
  </main>;
}

export default App;
