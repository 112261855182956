import {useEffect, useState} from "react";
import 'barcode-detector-polyfill';

export function Scanner(props) {
  if (!('BarcodeDetector' in window)) {
    window.BarcodeDetector = require('barcode-detector-polyfill');
  }

  const [lastCode, setLastCode] = useState(undefined);
  const handleNewCode = props.handleNewCode;

  useEffect(() => {
    console.log("initing");

    const handleScannedCode = code => {
      console.log("lastCode", lastCode, "current code", code);
      if (lastCode === code) return;
      setLastCode(code);
      props.handleNewCode(code);
    }

    (async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: {
            ideal: "environment"
          }
        },
        audio: false
      });
      const videoEl = document.querySelector("#stream");
      videoEl.srcObject = stream;
      await videoEl.play();
    })();

    // eslint-disable-next-line no-undef
    const barcodeDetector = new BarcodeDetector({formats: ['code_128']});
    const videoEl = document.querySelector("#stream");
    const interval = window.setInterval(async () => {
      const barcodes = await barcodeDetector.detect(videoEl);
      if (barcodes.length <= 0) return;
      new Set(barcodes.map(barcode => barcode.rawValue)).forEach(code => handleScannedCode(code));
    }, 500);

    return () => window.clearInterval(interval)
  }, [lastCode, handleNewCode]);

  return (
    <div id="reader">
      <video id="stream"/>
    </div>
  );
}